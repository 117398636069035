<template>
    <div class="custom-input">
        <v-select 
            persistent-placeholder
            :value="fieldValue"
            outlined
            :rules="rules"
            :readonly="isReadOnly"
            :label="label"
            :placeholder="placeholder"
            :no-data-text="noDataText"
            :multiple="isMultiple"
            item-text="text"
            item-value="value"
            :items="options"
            @blur="updateValueOnBlur()"
            @change="updateValueOnChange($event)"
            :hide-details="hideDetails"
            :disabled="disabled"
            :dense="dense"
            :loading="isLoading"
            :menu-props="{ bottom: true, offsetY: true }"
            :append-icon="hideArrowIon ? '' : '$dropdown'"
        > </v-select>
    </div>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        fieldValue: {
            type: String | Array,
            required: true,
            default: ''
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        noDataText: {
            type: String,
            required: false,
            default: 'No option'
        },
        isMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        },
        hideArrowIon: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        isReadOnly() {
            return this.readonly || this.$store.getters.isLoading
        },
        rules() {
            return [
                v => {
                    return this.required ? (this.$validate.required(v) && (typeof v !== 'number' || (typeof v === 'number' && v > -1)) || 'Required') : true
                },
            ]
        },
    },
    data: () => ({}),
    methods: {
        updateValueOnBlur() {
            if (this.isMultiple) {
                this.$emit('changed', this.fieldValue);
            }

        }, 
        updateValueOnChange(value) {
            this.$emit('update:fieldValue', value);
            if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
            }
            
            if (!this.isMultiple) {
                this.$emit('changed', value);
            }
        }
    }
}
</script>